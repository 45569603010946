import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import env from "~/app/env.server";
import { authenticate } from "~/app/shopify.server";
import { getShop } from "~/app/utils/shop.server";
import type { verificationStatusSchema } from "../constants/types/api";

// For test mode flows
// const SANDBOX_PARTNER_USERNAME = "plugins@escrow.com";
// const SANDBOX_PARTNER_API_KEY =
//   "3310_8zUz8RI83ZRCDeQa9lb8h8nNBbIblIBZkjX6sTtGuSTlSELDbjKtvgwVKGUqqJUr";
const SANDBOX_API_URL = "https://api.escrow-sandbox.com";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export async function getShopName(request: Request) {
  const { session } = await authenticate.admin(request);
  return getShop(session.shop);
}

export function getAuthString(testMode: boolean = false) {
  if (testMode) {
    return Buffer.from(
      `${env.SANDBOX_ESCROW_PARTNER_USERNAME}:${env.SANDBOX_ESCROW_PARTNER_API_KEY}`,
    ).toString("base64");
  }
  return Buffer.from(
    `${env.ESCROW_PARTNER_USERNAME}:${env.ESCROW_PARTNER_API_KEY}`,
  ).toString("base64");
}

export function getApiUrl(testMode: boolean = false) {
  if (testMode) {
    return SANDBOX_API_URL;
  }
  return env.ESCROW_API_URL;
}

export function getPartnerUsername(testMode: boolean = false) {
  if (testMode) {
    return env.SANDBOX_ESCROW_PARTNER_USERNAME;
  }
  return env.ESCROW_PARTNER_USERNAME;
}

export function isOnboardingComplete(
  verificationResponse: verificationStatusSchema,
) {
  if (!verificationResponse) {
    return false;
  }

  const {
    company,
    personal,
    extended,
    verified,
    customer_type: customerType,
  } = verificationResponse;

  const status = {
    NOT_REQUIRED: "not_required",
    VERIFIED: "verified",
    NOT_VERIFIED: "not_verified",
    PENDING: "pending",
    REJECTED: "rejected",
  };

  const submissionRequiredStatuses = [status.REJECTED, status.NOT_VERIFIED];
  const extendedRequired = submissionRequiredStatuses.includes(extended.status);
  const companyRequired =
    customerType === "company" &&
    submissionRequiredStatuses.includes(company.status);
  const personalRequired = submissionRequiredStatuses.includes(personal.status);
  const actionRequired =
    extendedRequired || companyRequired || personalRequired;

  const noExtendedPending = [status.NOT_REQUIRED, status.VERIFIED].includes(
    extended.status,
  );

  return verified && !actionRequired && noExtendedPending;
}
